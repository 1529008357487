<template>
  <div>
    <b-row>
      <b-col cols="12" md="4" lg="4">
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="FileTextIcon"
          :statistic="statistic.active"
          statistic-title="Aktif"
        />
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="FileTextIcon"
          :statistic="statistic.weekly"
          statistic-title="Minggu Ini"
        />
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="FileTextIcon"
          :statistic="statistic.monthly"
          statistic-title="Bulan Ini"
        />
      </b-col>
    </b-row>
    <b-row>
      <!-- card collapsible  -->
      <b-col md="12">
        <b-card-actions title="Filter" :collapsed="true" action-collapse>
          <b-row>
            <b-col md="3">
              <b-form-group label="Kode" label-for="code">
                <b-form-input
                  id="code"
                  placeholder="Kode"
                  v-model="filter.code"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Nama Pelanggan" label-for="customer_name">
                <b-form-input
                  id="customer_name"
                  placeholder="Nama Pelanggan"
                  v-model="filter.customer_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Nopol" label-for="Nopol">
                <b-form-input
                  id="Nopol"
                  placeholder="Nopol"
                  v-model="filter.nopol"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Status" label-for="status">
                <b-form-select
                  id="status"
                  v-model="filter.selectedStatus"
                  :options="filter.optionsStatus"
                />
                <!-- Selected: <strong>{{ filter.selectedStatus }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Tanggal Mulai" label-for="start_date">
                <flat-pickr
                  id="start_date"
                  v-model="filter.start_date"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Mulai"
                />
                <!-- <p>Value: '{{ filter.start_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Tanggal Berakhir" label-for="end_date">
                <flat-pickr
                  id="end_date"
                  v-model="filter.end_date"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Berakhir"
                />
                <!-- <p>Value: '{{ filter.end_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mt-1"
                @click="
                  getInquiry(
                    1,
                    filter.code,
                    filter.customer_name,
                    filter.start_date,
                    filter.end_date,
                    filter.selectedStatus
                  )
                "
              >
                Cari
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right mb-1">
        <router-link to="/contract/add">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            pill
            class="px-3"
          >
            Tambah Kontrak Baru
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-card>
      <!-- search input -->
      <!-- <b-row>
                <b-col class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                            />
                        </div>
                    </b-form-group>
                </b-col>
            </b-row> -->

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Created at -->
          <span
            v-else-if="props.column.field === 'start_date'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.start_date }}</span>
          </span>

          <span
            v-else-if="props.column.field === 'end_date'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.end_date }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'" class="text-nowrap">
            <b-badge :variant="statusVariant(props.row.status)">
              <span class="text-nowrap">{{ props.row.status_name }}</span>
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-bind:to="{ path: '/contract/' + props.row.id }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-cancel
                  @click="trigger(props.row.id)"
                  v-if="props.row.status < 5"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Batalkan</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-finish
                  @click="trigger(props.row.id)"
                  v-if="props.row.status === 5"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Selesai</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ meta.from }} - {{ meta.to }} </span>
              <span class="text-nowrap">&nbsp;dari {{ meta.total }} data </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getInquiry(
                      value,
                      filter.code,
                      filter.customer_name,
                      filter.nopol,
                      filter.start_date,
                      filter.end_date,
                      filter.selectedStatus
                    )
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <b-modal
      id="modal-cancel"
      title="Pembatalan"
      ok-title="Kirim"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <validation-observer ref="loginValidation">
        <b-form ref="form" @submit.prevent>
          <b-form-group
            :state="errors"
            label="Alasan Pembatalan"
            label-for="cancel"
            invalid-feedback="Alasan pembatalan tidak boleh kosong"
          >
            <validation-provider
              #default="{ errors }"
              name="cancel"
              rules="required"
            >
              <b-form-textarea
                id="cancel"
                name="cancel"
                type="text"
                v-model="form.cancelReason"
                :state="errors.length > 0 ? false : null"
                placeholder="Alasan Pembatalan"
                required="required"
              />
              <small class="text-danger">{{ errors[0] || errMessage }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col>
              <b-button
                class="mt-1 mb-2"
                type="submit"
                variant="danger"
                block
                @click="cancel()"
              >
                Kirim
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-finish"
      title="Penyelesaian Kontrak"
      ok-title="Kirim"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <validation-observer ref="loginValidation">
        <b-form ref="form" @submit.prevent>
          <b-form-group
            :state="errors"
            label="Note"
            label-for="finish"
            invalid-feedback="note tidak boleh kosong"
          >
            <validation-provider
              #default="{ errors }"
              name="finish"
              rules="required"
            >
              <b-form-textarea
                id="finish"
                name="finish"
                type="text"
                v-model="form.finishReason"
                :state="errors.length > 0 ? false : null"
                placeholder="note"
                required="required"
              />
              <small class="text-danger">{{ errors[0] || errMessage }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col>
              <b-button
                class="mt-1 mb-2"
                type="submit"
                variant="danger"
                block
                @click="finish()"
              >
                Kirim
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  VBModal,
  BForm,
  BFormFile,
  BModal,
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BContainer,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardActions,
    VueGoodTable,
    ToastificationContent,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormFile,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    StatisticCardHorizontal,
    BContainer,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Kode",
          field: "code",
        },
        {
          label: "Nama Pelanggan",
          field: "customer.name",
        },
        {
          label: "Nopol",
          field: "unit.police_no",
          width: "150px",
        },
        {
          label: "Tanggal Mulai",
          field: "start_date",
          width: "150px",
        },
        {
          label: "Tanggal Berakhir",
          field: "end_date",
          width: "150px",
        },
        {
          label: "Status",
          field: "status",
          width: "150px",
        },
        {
          label: "",
          field: "action",
          width: "50px",
        },
      ],
      filter: {
        code: "",
        customer_name: "",
        nopol: "",
        start_date: "",
        end_date: "",
        selectedStatus: "",
        optionsStatus: [
          { value: "", text: "Semua" },
          { value: 0, text: "Tertunda" },
          { value: 1, text: "Sedang Berjalan" },
          { value: 2, text: "Selesai" },
        ],
      },
      id: null,
      form: {
        reject_reason: "",
        finish_reason: "",
        cancel_reason: "",
      },
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      search: "",
      rows: [],
      searchTerm: "",
      errors: "",
      errMessage: "",
      file: null,
      statistic: null,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-dark",
        1: "light-warning",
        2: "light-warning",
        3: "light-warning",
        4: "light-warning",
        5: "light-primary",
        5: "light-success",
        9: "light-danger",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getInquiry(1, "", "", "", "", "");
    this.getStatistic();
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getInquiry(
      $page,
      $code,
      $nopol,
      $customer_name,
      $start_date,
      $end_date,
      $status
    ) {
      this.$http
        .get(
          "sales/contracts?page=" +
            $page +
            ($code ? "&code=" + this.filter.code : "") +
            ($customer_name ? "&customer=" + this.filter.customer_name : "") +
            ($nopol ? "&nopol=" + this.filter.nopol : "") +
            ($start_date ? "&start_date=" + this.filter.start_date : "") +
            ($end_date ? "&end_date=" + this.filter.end_date : "") +
            ($status ? "&status=" + this.filter.selectedStatus : "")
        )
        .then((res) => {
          this.rows = res.data.data;
          // console.log(res.data.data)
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    trigger($id) {
      this.id = $id;
    },
    process(id) {
      this.$http
        .post("sales/contract/" + id + "/process")
        .then((response) => {
          // console.log(response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Kontrak Diproses",
              variant: "success",
            },
          });
          location.href = "/contracts";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    cancel(id) {
      this.$http
        .post("sales/contract/" + id + "/cancel", {
          cancel_reason: this.form.cancelReason,
        })
        .then((response) => {
          // console.log(response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Kontrak Dibatalkan",
              variant: "success",
            },
          });
          location.href = "/contracts";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    finish(id) {
      this.$http
        .post("sales/contract/" + id + "/finish", {
          finish_reason: this.form.finishReason,
        })
        .then((response) => {
          // console.log(response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Kontrak Selesai",
              variant: "success",
            },
          });
          location.href = "/contracts";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getStatistic() {
      this.$http
        .get("sales/contracts/statistic")
        .then((response) => {
          this.statistic = response.data.data;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>